import classes from './PromoCodesListModal.module.scss'
import promoGiftImg from '../../../assets/images/gift icon.png'
import { IoIosArrowForward } from "react-icons/io";
import Button from '../../ui/Button';
import { useEffect, useMemo, useState } from 'react';
import percentageIcon from '../../../assets/images/percentage icon.png'
import words from '../../../assets/words';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { READ_PROMOCODES } from '../../../graphql/main/queries';
import { longDateformat } from '../../../utils/formatWords';
import SpinnerApp from '../../ui/SpinnerApp';
import { saveChangePromo } from '../../../store/slices/home';
import withModal from '../../ui/withModal';
import ConfirmationMessage from '../../ui/ConfirmationMessage';
import { useNavigate } from 'react-router-dom';

const PromoCodesListModal = ({ openPromoRedeemModal }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { language, planData } = useSelector((state) => state.auth);
    const { changeInPromoCode } = useSelector(state => state.home)
    const location = window.location.pathname
    const [promosList, setPromosList] = useState([])
    const [currentPromoTab, setCurrentPromoTab] = useState("Active") //Active or Used - Based on backend deal
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({})
    const [error, setError] = useState("")

    const [readPromoCodes, { loading }] = useLazyQuery(READ_PROMOCODES, {
        fetchPolicy: changeInPromoCode === true ? "cache-and-network" : "cache-first",
        onCompleted: (data) => {
            (promosList?.length > 0 && currentPromoTab === "Used")
                ? setPromosList([...promosList, ...data?.readWebPromoCode?.data?.list])
                : setPromosList(data.readWebPromoCode.data.list);
            setPagination(data.readWebPromoCode.data.pagination)
            dispatch(saveChangePromo(false))
        }
    })

    useEffect(() => {
        readPromoCodes({
            variables: {
                type: currentPromoTab,
                page: 1
            }
        })
    }, [currentPromoTab])

    let pages = pagination?.totalDocuments / pagination?.viewLimit
    const handleBottomReached = () => {
        if (page <= Math.round(pages)) {
            setPage(page + 1)
            readPromoCodes({
                variables: { page: page + 1, type: currentPromoTab }
            })
        }
    }

    const handleScroll = (e) => {
        const bottomThreshold = 1; // Adjust this threshold as needed
        if ((e.target.offsetHeight + e.target.scrollTop + bottomThreshold) >= e.target.scrollHeight) {
            if (handleBottomReached) {
                handleBottomReached();
            }
        }
    };

    const handleOpenAPromoCode = (row) => {
        if (!JSON.parse(planData)?.isFreeTrial && !JSON.parse(planData)?.isSubscribed) {
            setError("Yo're not subscripe")
            return 
        }
        if(JSON?.parse(planData)?.isFreeTrial){
            setError("You're in a free trail, you must subscripe first.")
        } else {
            row ? openPromoRedeemModal(row) : openPromoRedeemModal({})
        }
    }
    const [showCancelEdit, setShowCancelEdit] = useState(false);
    const CancelModal = useMemo(() => withModal(ConfirmationMessage), []);

    return (
        <div className={classes.container}>
            <CancelModal
        title={words.CANCEL_EDIT[language]}
        img
        message={words.DO_YOU_WANT_TO_DISCARD_YOUR_CHAGES[language]}
        show={showCancelEdit}
        onHide={() => setShowCancelEdit(false)}
        confirm={() => {
          setShowCancelEdit(false)
        }}
      />
            {error && <span className='text-danger text-center'>{error}</span>}
            {error && <span onClick={() => {
                if (!location.includes("update-card")) {
                navigate("/plans/upgrade")
                } else {
                setShowCancelEdit(true);
                }
            }}
           className='text-center' style={{cursor:'pointer'}}>{error === "You're in a free trail, you must subscripe first." && 'Subscribe Now'}</span>}
            <div className={classes.havePromoCode} onClick={() => { handleOpenAPromoCode() }}>
                <div> <img className={classes.icon} src={promoGiftImg} alt="gift icon" /> </div>
                <div className={classes.content}>
                    <h3 >{words.HAVE_PROMO_CODE[language]}</h3>
                    <p>{words.REDEEM_YOUR_PROMO_AND_ENJOY_WITH_THE_DISCOUND[language]}</p>
                </div>
                <div > <IoIosArrowForward /> </div>
            </div>

            <div className={classes.actions}>
                <Button onClick={() => {
                    setCurrentPromoTab("Active")
                    currentPromoTab === "Used" && setPromosList([])
                }} style={{
                    width: "98%",
                    padding: "15px 8px",
                    border: "none",
                    backgroundColor: currentPromoTab === "Active" ? "var(--primaryColor)" : "#EEE",
                    color: currentPromoTab === "Active" ? "#EEE" : "var(--primaryColor)",
                }}>
                    {words.ACTIVE[language]}
                </Button>

                <Button onClick={() => {
                    setCurrentPromoTab("Used")
                    currentPromoTab === "Active" && setPromosList([])
                }} style={{
                    width: "98%",
                    padding: "15px 8px",
                    border: "none",
                    backgroundColor: currentPromoTab === "Used" ? "var(--primaryColor)" : "#EEE",
                    color: currentPromoTab === "Used" ? "#EEE" : "var(--primaryColor)",
                }}>
                    {words.USED[language]}
                </Button>

            </div>

            <div className={classes.promoCodesContainer} onScroll={(e) => { currentPromoTab === "Used" && handleScroll(e) }}>
                {
                    loading ? <SpinnerApp />
                        :
                        promosList.length > 0 ? promosList.map((row, index) => (
                            <div className={classes.promoItem} key={index} onClick={() => { currentPromoTab === "Active" && handleOpenAPromoCode(row) }} style={{ cursor: currentPromoTab === "Active" && "pointer" }}>
                                <img src={percentageIcon} alt="icon" />
                                <div className={classes.promoCodeValue} style={{ borderBottom: index === promosList.length - 1 ? "none" : " 1px solid #D9D9D9" }}>
                                    <div className={classes.title}>{row.code} {currentPromoTab === "Used" && <span> - {row.percent_off ? `${row.percent_off}%` : row.amount_off} Discount</span>} </div>
                                    {
                                        currentPromoTab === "Active" ?
                                            <div className={classes.status}>
                                                {row.percent_off ? `${row.percent_off}%` : row.amount_off} Discount, till {longDateformat(row.expires_at)}
                                            </div>
                                            :
                                            <div className={classes.status}>
                                                Started at {longDateformat(row.created)} - Expired at {longDateformat(row.expires_at)}
                                            </div>
                                    }
                                </div>
                            </div>
                        ))
                            :
                            <div className={classes.nodata}>
                                <p>{words.NO_PROMO_CODES_FOUND[language]}</p>
                            </div>
                }
            </div>

        </div >
    )

}


export default PromoCodesListModal