import { Box, IconButton, MenuItem } from "@mui/material";
import { clearUsersIds, clearUsersPage } from "../../store/slices/users";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { CREATE_CUSTOMER_SUPPORT_TICKET } from "../../graphql/main/mutations";
import ConfirmationMessage from "../ui/ConfirmationMessage";
import DoneModal from "../ui/DoneModal";
import ErrorModal from "../ui/ErrorModal";
import { FaRegUser } from 'react-icons/fa'
import HelpCenterModal from "./HelpCenterModal";
import { HiOutlineLogout } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { LOGOUT } from "../../graphql/auth/mutations";
import { MdOutlineErrorOutline } from "react-icons/md";
import Menu from '@mui/material/Menu';
import PromoCodeRedeemModal from "./promocode/PromoCodeRedeemModal";
import PromoCodesListModal from "./promocode/PromoCodesListModal";
import { authActions } from "../../store";
import classes from "../layout/AuthLayout.module.scss";
import promoPic from "../../assets/images/promo.png"
import { saveToken } from "../../store/slices/auth";
import { uppercaseFirstLetter } from "../../utils/formatWords";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import withModal from "../ui/withModal";
import words from "../../assets/words";

const UserDropDown = ({ userData }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const { language, token, planData } = useSelector((state) => state.auth);

  const [anchorEl, setAnchorEl] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [showCancelEdit, setShowCancelEdit] = useState(false);
  const [showModals, setShowModals] = useState({
    showHelp: false,
    showDoneModal: false,
    showPromoCodesListModal: false,
    showPromoCodeRedeemModal: false,
    showPromoDoneModal: false,
    errorModal: {
      text: "",
      show: false
    }
  });
  const [messageData, setMessageData] = useState("")
  const [error, setError] = useState("")
  const [data, setData] = useState({
    select: "",
    message: "",
  });
  const [selectedPromo, setSelectedPromo] = useState({})
  const [userPlanData, setUserPlanData] = useState({})

  const [createTicket, { loading }] = useMutation(CREATE_CUSTOMER_SUPPORT_TICKET, {
    onCompleted: (data) => {
      if (data.createCustomerSupportTicket.success) {
        setMessageData(data?.createCustomerSupportTicket?.data)
        setShowModals({ ...showModals, showHelp: false, showDoneModal: true });
      } else {
        setError(data.createCustomerSupportTicket.message)
        setShowModals({ ...showModals, showHelp: false, errorModal: { text: data.createCustomerSupportTicket.message, show: true } });
      }
    },
    onError: (error) => {
      setError(error.message)
    }
  })
  const handleOnSend = (data) => {
    createTicket({
      variables:
      {
        reason: data.select.value,
        description: data.message
      }

    })
  };
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget)
    setOpenEvent(!openEvent)
  };

  useEffect(() => {
    dispatch(saveToken(token))
  }, [token])

  useEffect(() => {
    if(typeof planData == "string"){
      setUserPlanData(JSON.parse(planData))
    } else {
      setUserPlanData(planData)
    }
  },[planData])


  const [logout] = useMutation(LOGOUT, {
    onCompleted: (data) => {
      if (data.logout.success) {
        dispatch(authActions.logout());
        navigate("/auth")
        sessionStorage.removeItem("token")
      }
    }
  }
  )

  const logoutHandler = () => {
    setOpenEvent(false);
    logout()

  };
  const helpHandler = () => {
    setShowModals({ ...showModals, showHelp: true })
    setOpenEvent(false)

  };

  const goToProfile = () => {
    if (!location.includes("update-card")) {
      setOpenEvent(false);
      navigate("/profile");
      dispatch(clearUsersIds())
      dispatch(clearUsersPage())
    } else {
      setShowCancelEdit(true);
      setOpenEvent(false);
    }
  }

  const CancelModal = useMemo(() => withModal(ConfirmationMessage), []);
  const HelpModal = useMemo(() => withModal(HelpCenterModal), [])
  const HelpDoneModal = useMemo(() => withModal(DoneModal), [])
  const ErrorTicket = useMemo(() => withModal(ErrorModal), [])
  const PromoCodesModal = useMemo(() => withModal(PromoCodesListModal), [])
  const PromoRedeemModal = useMemo(() => withModal(PromoCodeRedeemModal), [])
  const PromoDone = useMemo(() => withModal(DoneModal), [])

  return (
    <div className={classes.profileInfoContainer}>
      <PromoCodesModal
        show={showModals.showPromoCodesListModal}
        title={words.PROMO_CODES[language]}
        onHide={() => setShowModals({ ...showModals, showPromoCodesListModal: false })}
        openPromoRedeemModal={(data) => {
          data && setSelectedPromo(data)
          setShowModals({ ...showModals, showPromoCodesListModal: false, showPromoCodeRedeemModal: true })
        }}
        dialogClassName={classes.modal}

      />
      <PromoRedeemModal
        show={showModals.showPromoCodeRedeemModal}
        title={words.PROMO_CODES[language]}
        onHide={() => setShowModals({ ...showModals, showPromoCodeRedeemModal: false })}
        dialogClassName={classes.modal}
        promoData={selectedPromo}
        onSuccess={() => setShowModals({...showModals, showPromoCodeRedeemModal: false, showPromoDoneModal: true})}
      />

      <PromoDone
        show={showModals.showPromoDoneModal}
        title={words.DONE[language]}
        onHide={() => setShowModals({ ...showModals, showPromoDoneModal: false })}
        onDone={() => {
          setShowModals({ ...showModals, showPromoDoneModal: false });
        }}
      />

      <HelpDoneModal
        show={showModals.showDoneModal}
        title={words.DONE[language]}
        onHide={() => setShowModals({ ...showModals, showDoneModal: false })}
        onDone={() => {
          setShowModals({ ...showModals, showDoneModal: false });
        }}
        doneTitle={messageData?.ticketId}
        showModals={showModals}
        setShowModals={setShowModals}

      />
      <HelpModal
        title={words.HELP[language]}
        show={showModals.showHelp}
        onHide={() => setShowModals({ ...showModals, showHelp: false })}
        showHelp={showModals.showHelp}
        showModals={showModals}
        setShowModals={setShowModals}
        onSend={handleOnSend}
        DoneLoading={loading}

      />
      <CancelModal
        title={words.CANCEL_EDIT[language]}
        img
        message={words.DO_YOU_WANT_TO_DISCARD_YOUR_CHAGES[language]}
        show={showCancelEdit}
        onHide={() => setShowCancelEdit(false)}
        confirm={() => {
          setShowCancelEdit(false)
          navigate('/profile')
        }}
      />
      <ErrorTicket
        show={showModals.errorModal.show}
        onHide={() => setShowModals({ ...showModals, errorModal: { text: "", show: false } })}
        title={words.ERROR[language]}
        content={showModals.errorModal.text}
        onDone={() => setShowModals({ ...showModals, errorModal: { text: "", show: false } })}
      />



      <IconButton
        aria-label="menu"
        color="inherit"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ borderRadius: "5px", gap: '10px', }}
      >
        <Box style={{
          gap: '10px',
        }} display="flex" alignItems="center">
          {
            userData?.profilePictureUrl &&
            <img src={userData?.profilePictureUrl} alt="user-pic" style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover" }} />
          }

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              className={classes.name}
              style={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: "bold"
              }}
            >
              {userData?.fullName && uppercaseFirstLetter(userData?.fullName)}   <IoIosArrowDown className="ms-2" size={15} />
            </span>
          </Box>
        </Box>
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openEvent}
        onClose={handleClick}
        sx={{
          "& .MuiMenu-paper": {
            width: "220px",
            borderRadius: "20px",
            fontFamily: "Poppins"
          },
          "& .MuiList-padding": {
            p: "15px",
          },
        }}
      >
        <MenuItem
          sx={{
            pt: 1,
            pb: 1,
            pl: 1,
            pr: 1,
          }}
          onClick={goToProfile}
          style={{
            borderBottom: '1px solid var(--greyColor400)'
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={'10px'}
            //   onClick={myProfileHandler}
            className={classes.item}
            style={{
              fontFamily: "Poppins",
            }}
          >
            <FaRegUser size={20} /> {words.MY_PROFILE[language]}
          </Box>
        </MenuItem>

        <MenuItem
          sx={{
            pt: 1,
            pb: 1,
            pl: 1,
            pr: 1,
          }}
          onClick={helpHandler}
          style={{
            borderBottom: '1px solid var(--greyColor400)',
            fontFamily: "Poppins",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={'10px'}
            //   onClick={myProfileHandler}
            className={classes.item}
          >
            <MdOutlineErrorOutline size={20} /> {words.HELP_CENTER[language]}
          </Box>
        </MenuItem>
        {(!userPlanData?.isFreeTrial && userPlanData?.isSubscribed) && 
            <MenuItem
            sx={{
              pt: 1,
              pb: 1,
              pl: 1,
              pr: 1,
            }}
            onClick={() => { setShowModals({ ...showModals, showPromoCodesListModal: true }); setOpenEvent(false) }}
            style={{
              borderBottom: '1px solid var(--greyColor400)',
              fontFamily: "Poppins",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={'10px'}
              className={classes.item}
            >
              <img src={promoPic} alt='promo pic' style={{ width: 20, height: 20 }} />
              <span>{words.PROMO_CODES[language]}</span>
            </Box>
          </MenuItem>
        } 
    
        <MenuItem
          sx={{
            pt: 1,
            pb: 1,
            pl: 1,
            pr: 1,
          }}
          onClick={logoutHandler}
          style={{
            fontFamily: "Poppins",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={'10px'}
          >
            <HiOutlineLogout size={20} /> {words.LOG_OUT[language]}
          </Box>
        </MenuItem>
      </Menu>
    </div>

  );
};

export default UserDropDown;
